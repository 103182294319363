import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from "@mui/styles"
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton, useMediaQuery } from "@mui/material";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { appTheme } from '../theme/colorTheme';
import { getImgSrc } from '../constants/Helper';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles(() => ({
    root: {
        "& .MuiMobileStepper-dot": { backgroundColor: appTheme.color9 },
        "& .MuiMobileStepper-dotActive": { backgroundColor: appTheme.color12 }
    }
}));

const StyledIconButton = styled(IconButton)`
height: 35px;
width: 35px;
position: absolute;
border-radius: 100%;
right:${(props) => (props?.isleft === "true" ? "none" : "0")};;
left:${(props) => (props?.isleft === "true" ? "0" : "none")};
top:35%;
background-color: rgba(0, 0, 0, 0.5);
color: white;
border-color: rgba(0, 0, 0, 0.3);
z-index: 1;
opacity: 1;
:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.5), 0px 1px 10px rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
`;


const CarouselSliderList = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.data.length;
    const smallDevice = useMediaQuery('(max-width:500px)');
    const extraSmallDevice = useMediaQuery('(max-width:400px)');
    const sliderImgHieght = !!extraSmallDevice ? 165 : smallDevice?190:250;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
    const handleStepChange = (step) => {
        setTimeout(() => {
            setActiveStep(step);
        },500)
    };
    const Slider = () => {
        return (
            <Box sx={{ flexGrow: 1, mt:1, position:'relative' }}>

                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'black'}}>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {props.data.map((step, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor:'pointer', 
                            }} 
                                onClick={() => {
                                    if (!!props.navigate) {
                                        const { type, details_url } = step;
                                        if (type.toLocaleLowerCase() === "url") window.open(details_url);
                                        if(type.toLocaleLowerCase() === "createid") props.navigate('/ids?index=1');
                                        if(type.toLocaleLowerCase() === "depositcoin") props.navigate('/walletDeposit');
                                    }
                                }} 
                            >
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <img
                                        style={{
                                            boxShadow:"0 0 10px rgba(0, 0, 0, 0.3)",
                                            display: 'block',
                                            overflow: 'hidden',
                                            width:'100%',
                                            height:`${sliderImgHieght}px`
                                            
                                        }}

                                        src={!!step.isStatic? step.image_url : !!getImgSrc(step?.image_url) ? `${getImgSrc(step.image_url)}`:
                                            require('../img/no-pictures.png')}
                                        alt="slider"
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    { props.data.length !==1 && <>
                        <StyledIconButton isleft="false" onClick={activeStep === maxSteps - 1?null:handleNext}>
                        <ArrowForwardIosRoundedIcon />
                    </StyledIconButton>
                    <StyledIconButton isleft="true" onClick={activeStep === 0?null:handleBack}>
                        <ArrowBackIosNewRoundedIcon />
                    </StyledIconButton>
                    </>}
                </Box>
                <Box>
              
                           
                            
                    <MobileStepper
                        variant="dots"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        classes={classes}
                    />
                </Box>



            </Box>
        )
    }
    const SliderMemo = React.memo(Slider);

    return (
        <SliderMemo />
    )
}
const CarouselSlider = React.memo(CarouselSliderList);
export default CarouselSlider;