import React from "react";
import {
    ThemeProvider,
    CssBaseline,
    Typography,
    Button,
    Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import CustomizedInputs from "../components/customComponent/CustomInput";
import axiosService from "../axios-api-kit/axiosService";
import InformationDailog from "./InformationDailog";
import RadioButton from "../components/customComponent/RadioButton";
import { AuthContext } from "../context/AuthProvider";
import { appTheme } from "../theme/colorTheme";
import { getImgSrc } from "../constants/Helper";

const WithdrawIdScreen = () => {
    const { getDepositOrWithdrawIdData } = React.useContext(AuthContext);
    const props = getDepositOrWithdrawIdData();
    const [withdrawCoin, setWithdrawCoin] = React.useState(props?.website?.min_withdrawal);
    const [isWithdrawCoinValid, setIsWithdrawCoinValid] = React.useState(true);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState('');
    const [statusMessage, setStatusMessage] = React.useState('');
    const [navigateUrl, setNavigateUrl] = React.useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
    const [buttonTextCancel, setButtonTextCancel] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState("");

    const checkWithdrawCoin = (value) => {
        if (value.length <= 0 || value === '0' || +value < +props?.website?.min_withdrawal || +value > +props.website.max_withdrawal) {
            setErrorMsg(`Minimum withdrawal amount should be at least ${props?.website?.min_withdrawal} coins and Maximum withdrawal is ${props.website.max_withdrawal} coins.`)
            setIsWithdrawCoinValid(false);
        } else {
            setIsWithdrawCoinValid(true);
        }
    }

    const continueToPay = (WithdrawCoinValueCheck) => {
        checkWithdrawCoin(WithdrawCoinValueCheck);

        if (isWithdrawCoinValid && WithdrawCoinValueCheck.length !== 0 && WithdrawCoinValueCheck !== '0' && +WithdrawCoinValueCheck <= +props.website.max_withdrawal) {
            const payload = new URLSearchParams()
            payload.append('user_website_id', props?.id)
            payload.append('requested_amount', +withdrawCoin)
            payload.append('to_wallet', 1)
            axiosService.withdrawIdTransaction(payload).then((res) => {
                setResponseStatus('success');
                setStatusMessage("Your request has been submitted successfully!");
                setButtonTextConfirm('Ok')
                setButtonTextCancel('Cancel')
                setNavigateUrl('/');
                setOpenInfo(true)
            })
                .catch((error) => {
                    setResponseStatus('error');
                    setStatusMessage("Your request has been failed!");
                    setButtonTextConfirm('Ok')
                    setButtonTextCancel('Cancel')
                    setNavigateUrl('');
                    setOpenInfo(true)
                })
        }


    }

    React.useEffect(() => {

    }, [])


    return (
        <React.Fragment>
            {!!openInfo ? <InformationDailog
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                status={responseStatus}
                statusMessage={statusMessage}
                buttonTextConfirm={buttonTextConfirm}
                buttonTextCancel={buttonTextCancel}
                navigateUrl={navigateUrl} /> : ''}
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 1
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            mb: 3,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 1,
                                width: 50,
                                height: 45,
                                borderRadius: 2,
                                backgroundColor: appTheme.color11,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={!!getImgSrc(props?.website?.logo_url) ? getImgSrc(props.website.logo_url) : require('../img/no-pictures.png')}
                                width="40"
                                alt="logo"
                                loading="lazy"
                            />
                        </Box>

                        <Typography variant="body1" gutterBottom component="div">
                            {props?.website?.name}
                        </Typography>
                        <Typography variant="caption" display="block">
                            {props?.website?.access_url}
                        </Typography>
                        {/* <Typography variant="caption" gutterBottom display="block">
                            {props?.website?.demo_id}
                        </Typography> */}
                        <Typography variant="caption" gutterBottom display="block">
                            Daily Withdraw limit: {props?.website?.max_withdrawal}
                        </Typography>
                    </Stack>

                    <CustomizedInputs key={"1"} placeholder="Enter Coins"
                        label={`Coins* (Minimum Withdraw amount is ${props?.website?.min_withdrawal} coins)`}
                        type="number"
                        errorMsg={errorMsg}
                        defaultValue={withdrawCoin}
                        setData={setWithdrawCoin}
                        isValidation={isWithdrawCoinValid}
                        checkValidation={checkWithdrawCoin} />
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="left"
                        sx={{ mt: 3, mb: 3, border: `1px dashed ${appTheme.color17}`, padding: 1 }}
                    >
                        <RadioButton />
                        <Typography
                            variant="caption"
                            display="block"
                            sx={{ color: appTheme.color7, mt: 1.3 }}
                        >
                            Withdraw to Wallet
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Button onClick={() => {
                            continueToPay(withdrawCoin);
                        }}
                            sx={{
                                color: appTheme.color0, bgcolor: appTheme.color12,
                                "&:hover": {
                                    bgcolor: appTheme.color12
                                },
                                focusVisible: false,
                                pb: 1, pt: 1
                            }}> <Typography
                                variant="caption"
                                display="block"
                                gutterBottom>Submit</Typography></Button>

                    </Stack>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};

const WithdrawId = React.memo(WithdrawIdScreen);

export default WithdrawId;
