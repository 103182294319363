import React from "react";
import axios from 'axios'
import { localStorageKey } from '../constants/LocalStorageKey';
import { AuthContext } from "../context/AuthProvider";
import constant from "../constants/Constatnt";

const apiKit = axios.create({
    baseURL: constant.baseUrl 
})
const WithAxios = ({ children }, data) => {
    const { loadingState, showNotification, signOut } = React.useContext(AuthContext)
    React.useMemo(() => {
        apiKit.interceptors.request.use((config) => {
            if(!JSON.parse(localStorage.getItem(localStorageKey.ISDEVTOOLOPEN))){
                loadingState(true);
                const token = localStorage.getItem(localStorageKey.APITOKEN);
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token
                }
                // config.headers['Content-Type'] = 'application/json';
                return config
            }
            
        })
        apiKit.interceptors.response.use((response) => {
            loadingState(false);
            return response;
        }, (error) => {
            if(!JSON.parse(localStorage.getItem(localStorageKey.ISDEVTOOLOPEN))){
            loadingState(false);
            if(error?.response?.data?.errors?.sent_count){
                showNotification(true, error?.response?.data?.errors.sent_count[0]);
            }else{
                showNotification(true, !!error?.response?.data?.message ? error.response.data.message : error.message);
            }
            
            if (error.response.status === 429 || error.response.status === 401) {
                signOut();
            }
            return Promise.reject(error)
            }else{
                showNotification(true, "Devtools open, Close it and refresh the page!");
            }
            
        })
    }, [loadingState, showNotification, signOut])

    return children
}
export { WithAxios }
export default apiKit;