import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axiosService from "../../axios-api-kit/axiosService";
import { dateConvert, timeDifference } from "../../constants/Helper";
import constant from "../../constants/Constatnt";
import TransactionDetails from "../../layouts/TransactionDetails";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullTransactionDetailDialogScreen = () => {
    const searchParams = useLocation().search;
    const location = useLocation();
    let navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(true);
    const [transactionData, setTransactionData] = React.useState(null);
    const handleDialogClose = () => {
        if(location.key === 'default'){
            navigate('/');
        }else{
            navigate(-1);
        }
        
        setOpenDialog(false);
    };
    const getTransactionData = React.useCallback(() => {
        const id = new URLSearchParams(searchParams).get('id');
        axiosService.getTransactionDetailsById(id).then((response) => {

            const transactionList = {}
            transactionList["remarks"] = response?.data?.reason_remarks
            transactionList["transactionRequest"] =
                response?.data?.transactionable_type === constant.transactionType.depositWallet || response?.data?.transactionable_type === constant.transactionType.withdrawWallet ? 'WalletRequest' :
                    response?.data?.transactionable_type === constant.transactionType.depositId || response?.data?.transactionable_type === constant.transactionType.withdrawId ? 'ExistingIdRequest' :
                        response?.data?.transactionable_type === constant.transactionType.changeIdPasswordRequest ? 'ChangePasswordRequest' : 'CreateIdRequest';
            transactionList["transactionType"] =
                response?.data?.transactionable_type === constant.transactionType.withdrawWallet || response?.data?.transactionable_type === constant.transactionType.withdrawId ? 'Withdraw' :
                    response?.data?.transactionable_type === constant.transactionType.depositWallet || response?.data?.transactionable_type === constant.transactionType.depositId ? 'Deposit' :
                        response?.data?.transactionable_type === constant.transactionType.changeIdPasswordRequest ? 'ChangePassword' : '';
            transactionList["status"] = response?.data?.status;
            transactionList["amount"] = response?.data?.transactionable?.requested_amount;
            transactionList["userId"] = response?.data?.user_id;
            transactionList["referenceNo"] = response?.data?.transaction_number;
            transactionList["requestedDate"] = dateConvert(response?.data?.created_at);
            transactionList["logoUrl"] = transactionList?.transactionRequest === 'CreateIdRequest' ? response.data.transactionable.website?.logo_url :
                transactionList?.transactionRequest === 'ExistingIdRequest' ? response.data.transactionable.user_website?.website.logo_url :
                transactionList?.transactionRequest === 'WalletRequest' && transactionList?.transactionType === 'Deposit' ? response.data.transactionable?.payment_method?.logo_url :
                transactionList?.transactionRequest === 'WalletRequest' && transactionList?.transactionType === 'Withdraw' ? response.data.user?.pref_payment_method?.logo_url :
                        transactionList?.transactionRequest === 'ChangePasswordRequest' ? response.data.transactionable.user_website?.website.logo_url : '';
            transactionList["demo_id"] = transactionList?.transactionRequest === 'CreateIdRequest' ? response.data.transactionable.website?.demo_id :
                transactionList?.transactionRequest === 'ExistingIdRequest' ? response.data.transactionable.user_website?.website.demo_id :
                    '';
            transactionList["brandName"] = transactionList?.transactionRequest === 'CreateIdRequest' ? response.data.transactionable.website?.name :
                transactionList?.transactionRequest === 'ExistingIdRequest' ? response.data.transactionable.user_website?.website.name :
                    transactionList?.transactionRequest === 'ChangePasswordRequest' ? response.data.transactionable.user_website?.website.name : '';
            transactionList["accessUrl"] = transactionList?.transactionRequest === 'CreateIdRequest' ? response.data.transactionable.website?.access_url :
                transactionList?.transactionRequest === 'ExistingIdRequest' ? response.data.transactionable.user_website?.website.access_url :
                    transactionList?.transactionRequest === 'ChangePasswordRequest' ? response.data.transactionable.user_website?.website.access_url : '';
            transactionList["startedDate"] = dateConvert(response?.data?.verified_at);
            transactionList["approvedOn"] = dateConvert(response?.data?.completed_at);
            transactionList["timetaken"] = timeDifference(response?.data?.created_at, response?.data?.completed_at);
            setTransactionData(transactionList)
        }).catch((error) => {
        })
    }, [searchParams])

    React.useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
        }else{
            getTransactionData();
        }
        

    }, [getTransactionData, location.key, navigate])

    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Transaction Details
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                    {!!transactionData ? <TransactionDetails transactionData={transactionData} /> : ''}
                </DialogContent>
            </Dialog>
        </>
    );
};
const TransactionDetailDailog = React.memo(FullTransactionDetailDialogScreen);
export default TransactionDetailDailog;
