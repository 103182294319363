import React, {useEffect, useRef} from "react";
import {
  CssBaseline,
  Skeleton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container } from "@mui/system";
import theme from "../../theme/theme";
import CarouselSlider from "../../layouts/Carousel";
import axiosService from "../../axios-api-kit/axiosService";
import { useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import MyIdHomeDetailCard from "../../layouts/myIdsHome";
import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { appTheme } from "../../theme/colorTheme";
import runOneSignal from "../../OneSignal";
import Marquee from "../../layouts/Marquee";
import DownloadAppModal from "../../layouts/DownloadAppModal";
import { isMobileDeviceAndroid } from "../../constants/Helper";
import { setIsIntersecting } from "../../utils/headerIntersectionSlice";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SnackBar from "../../layouts/SnackBar";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const { wallet_balance } = useSelector(store => store?.app?.userInfo);
  const {withdraw_status, hold_withdrawal_remarks } = useSelector(store => store?.app?.withdrawalStatus);
  const { content, title } = useSelector(store => store?.app?.commonHelpline);
  const { data: announcementNews } = useSelector(store => store?.app?.newsData);
  const [bannerData, setBannerData] = React.useState(null);
  const [openInfo, setOpenInfo] = React.useState(false);
  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.75,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      dispatch(setIsIntersecting(entry.isIntersecting))
    })
  }, options)

  const getBannerData = React.useCallback(() => {
    axiosService.getBanners().then((resp) => {
      setBannerData(resp.data);
    }).catch((error) => {
    })
  }, [])

  useEffect(() => {
    sessionStorage.clear(localStorageKey.SETIDSTABSTATE);
    getBannerData();
  }, []);
  
  useEffect(() => {
    runOneSignal();
    const timer = setTimeout(() => {
      setOpenInfo(false);
    }, 500);
    
    return () => {
      clearTimeout(timer);
    };
  },[]);

  useEffect(() => {
    observer.observe(elementRef.current);
    return () => {
      observer.disconnect();
      dispatch(setIsIntersecting(true));
    }
  }, [])
  return (
    <React.Fragment>
      <SnackBar
        setOpen={setOpenSnackbar}
        open={openSnackbar}
        message={snackBarMessage}
      />
      {
        isMobileDeviceAndroid() && (openInfo && 
        localStorage.getItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN) === null 
        && !!JSON.stringify(localStorage.getItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN))) && <DownloadAppModal 
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}/>
      }
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ color: appTheme.color1, paddingBottom: 2}}>
          <Box ref={elementRef} sx={{display: 'flex', justifyContent:'space-between', alignItems:'center',  background: "linear-gradient(45deg, #1a1a1a, #1a1a1a)", boxShadow:"0 0 10px rgba(0, 0, 0, 0.3)", borderRadius:2, pl:2, pb:1, pr:.5 }}>
            <Box sx={{pt:2}}>
              <Typography variant="h6" fontSize={15} fontWeight={"bold"} display="block" gutterBottom>
                My Wallet
              </Typography>
              <Typography variant="h5" fontWeight={"bold"} display="block" gutterBottom>
                <span style={{ color: appTheme.color6 }}>₹</span><span style={{ color: appTheme.color12 }}>{wallet_balance}</span>
              </Typography> 
            </Box>
            <Box sx={{display:'flex'}}>
              <Box
              sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1, cursor:'pointer'}} 
              onClick={() => {
                navigate('/walletDeposit');
              }}
              >
                   <img
                    src={require(`../../img/deposit_icon.png`)}
                    width="80"
                    alt="logo"
                    loading="lazy"
                  /> 
                  <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{mt:-3, justifyContent:'center'}}>
                 Deposit
                  </Typography>
              </Box>
              <Box 
              sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1, cursor:'pointer', opacity:withdraw_status === 'off' ? 0.4 : 1}}
              onClick={() => {
                if(withdraw_status === 'off'){
                  setSnackBarMessage(hold_withdrawal_remarks || 'Withdraw wallet on hold, we are under maintenance!');
                  setOpenSnackbar(true);
                }else{
                  navigate('/withdrawWallet')
                }
              }} >
                  <img
                    src={require(`../../img/withdraw_icon.png`)}
                    width="80"
                    alt="logo"
                    loading="lazy"
                  />
                  <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{mt:-3, justifyContent:'center'}}>
                  {withdraw_status === 'off' && <ConstructionOutlinedIcon fontSize="small"/>}
                   Withdraw
                  </Typography>
                </Box>
             
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            
            {
              !!bannerData ? <CarouselSlider data={bannerData} navigate={navigate}  /> :
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                </Box>
            }

          </Box>
         <Marquee anoucmentText={announcementNews}/>
          <Box>
            <MyIdHomeDetailCard />
          </Box>
        
          {/* <Box>
            <Stack sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 2,
              mt: 2
            }}>
              {
                !!content && !!title ?
                  <>
                    <Typography variant="caption" display="block" gutterBottom sx={{ color: appTheme.color12 }}>
                      Tips & Anouncements
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {title}<ReactWhatsapp number={`${content}`} message="" style={{
                        backgroundColor: appTheme.color12,
                        border: 0, borderRadius: 50, color: `black`, padding: '5px 10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="small" sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {content}
                      </ReactWhatsapp>
                    </Typography>
                  </> : <Box sx={{ width: '100%' }}>
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  </Box>
              }
            </Stack>
          </Box> */}
        </Container>
        <Typography variant="caption" display="block" gutterBottom><ReactWhatsapp number={`${content}`} message="" style={{
                        position: 'fixed', right: 15, bottom: 80,
                        backgroundColor: '#0FC555',
                        border: 0, borderRadius: 100, color: appTheme.color1, padding: '10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="large" sx={{ verticalAlign: 'middle'}} />
                      </ReactWhatsapp>
                    </Typography>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Home;
